// @flow strict
import React from 'react';
import { Link } from 'gatsby';
import Author from './Author';
import Comments from './Comments';
import Content from './Content';
import Meta from './Meta';
import Tags from './Tags';
import styles from './Post.module.scss';
import type { Node } from '../../types';
import Book from './Books';

type Props = {
  post: Node
};

type SubProps = {
  strLines: string[]
};

const CustomRecs = function ({ strLines }: SubProps) { 
  const html_book_one = '<iframe style="width:120px;height:240px;" marginwidth="0" marginheight="0" scrolling="no" frameborder="0" src="//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ac&ref=qf_sp_asin_til&ad_type=product_link&tracking_id=datastud-20&marketplace=amazon&region=US&placement=1509306978&asins=1509306978&linkId=17c6fa012e1154f4a81874b7f25f51fb&show_border=true&link_opens_in_new_window=true&price_color=333333&title_color=0066c0&bg_color=ffffff"></iframe>';
  const html_book_two = '<iframe style="width:120px;height:240px;" marginwidth="0" marginheight="0" scrolling="no" frameborder="0" src="//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ac&ref=tf_til&ad_type=product_link&tracking_id=datastud-20&marketplace=amazon&region=US&placement=1735365203&asins=1735365203&linkId=e493336778375b9d03d0d58f7a105013&show_border=true&link_opens_in_new_window=true&price_color=333333&title_color=0066c0&bg_color=ffffff"></iframe>';
  const html_book_three = '<iframe style="width:120px;height:240px;" marginwidth="0" marginheight="0" scrolling="no" frameborder="0" src="//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ac&ref=tf_til&ad_type=product_link&tracking_id=datastud-20&marketplace=amazon&region=US&placement=1509307958&asins=1509307958&linkId=5944098665117794e578a10c089d020e&show_border=true&link_opens_in_new_window=true&price_color=333333&title_color=0066c0&bg_color=ffffff"></iframe>';

  if(strLines == null){
    return (
      <div className={styles['post__book-recs']}>
        <div className={styles['post__book-recs__div-breakpoint']}></div>
        <h5 className={styles['post__book-recs__no-margin-top']}>Check out these books I recommend for learning Data Analytics and Power BI:</h5>
        <div className={styles['post__book-recs__book-row']}>
          <Book body={html_book_one}/>
          <Book body={html_book_two}/>
          <Book body={html_book_three}/>
        </div>
      </div>
    );
  } else {
    return (
      <div className={styles['post__book-recs']}>
        <div className={styles['post__book-recs__div-breakpoint']}></div>
        <h5 className={styles['post__book-recs__no-margin-top']}>For a deeper dive into some of the concepts related to this article, check out the following books:</h5>
        <div className={styles['post__book-recs__book-row']}>
          {strLines && strLines.map((line, i) => (
            <Book body={line}/>
          ))}
        </div>
      </div>
    );
  }
}

const Post = ({ post }: Props) => {
  const { html } = post;
  const { tagSlugs, slug } = post.fields;
  const { tags, title, date, recsCustom } = post.frontmatter;

  return (
    <div className={styles['post']}>
      <Link className={styles['post__home-button']} to="/">All Articles</Link>

      <div className={styles['post__content']}>
        <Content body={html} title={title} />
      </div>

      <div className={styles['post__footer']}>
        {tags && tagSlugs && <Tags tags={tags} tagSlugs={tagSlugs} />}
        <Author />
      </div>

      {/* Commenting out book recommendations */}
      {/* <CustomRecs strLines={recsCustom}/> */}
      
      <div className={styles['post__comments']}>
        <Comments postSlug={slug} postTitle={post.frontmatter.title} />
      </div>
    </div>
  );
};

export default Post;
